import React, { useState } from "react";
import "./style.css";
import { ReactComponent as FoodizICon } from "./assets/foodiz.svg";
import Logout from "./components/logout";
import ActionButton from "../../../../components/action-button";
import { useHistory } from "react-router-dom";
import { customStyles } from "../popup/customStyle";
import Popup from "../popup";
import PopupButton from "../../../../components/popup-button";
import Modal from "react-modal";
import { ReactComponent as SignoutIcon } from "./assets/signout.svg";

const Sidebar = () => {
  // Attributes
  const history = useHistory();
  const [isModalOpen, setIsModalOpen] = useState(false);

  // Handlers
  function renderNavButtons() {
    const routes = [
      "companies",
      "restaurants",
      "promos",
      "statistics-v2",
      "new-orders",
      "notifications",
      "zones",
      "app-versioning",
      "screens",
      "preparations-time",
    ];
    return routes.map((route) => {
      let isActive = history.location.pathname.includes(`dashboard/${route}`);
      if (
        route === "statistics?type=day" &&
        history.location.pathname.includes(`dashboard/statistics`)
      ) {
        isActive = true;
      }

      if (
        route === "new-orders" &&
        history.location.pathname.includes(`dashboard/new-orders`)
      ) {
        isActive = true;
      }

      if (
        route === "old-orders?type=day" &&
        history.location.pathname.includes(`dashboard/old-orders`)
      ) {
        isActive = true;
      }

      function isFunctionnalityInBeta(route: string) {
        if (route === "") return true;

        return false;
      }

      return (
        <>
          <ActionButton
            type="right"
            key={route}
            isActive={isActive}
            name={route}
            handleAction={() => history.push(`/dashboard/${route}`)}
            isBeta={isFunctionnalityInBeta(route)}
          />
          <div style={{ marginBottom: 28 }} />
        </>
      );
    });
  }

  function handleLogOut() {
    setIsModalOpen(false);
    window.localStorage.removeItem("ACCESS_TOKEN");
    history.replace("/");
    return;
  }

  return (
    <div className="sidebar-container">
      <div>
        <div
          style={{
            width: "fill",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <FoodizICon style={{ marginBottom: 10, width: "4.0625rem" }} />
        </div>
        <div className="menu-list-container">{renderNavButtons()}</div>
      </div>
      <div>
        <Logout handleClick={() => setIsModalOpen(true)} />
      </div>
      <Modal style={customStyles} isOpen={isModalOpen}>
        <Popup
          title="Are you sure you want to sign out?"
          icon={<SignoutIcon style={{ width: 66, height: 69 }} />}
          buttons={[
            <PopupButton
              backgroundColor="grey"
              handleClick={() => setIsModalOpen(false)}
              title="Cancel"
            />,
            <PopupButton
              backgroundColor="yellow"
              handleClick={handleLogOut}
              title="Yes, sign out"
            />,
          ]}
        />
      </Modal>
    </div>
  );
};

export default Sidebar;
