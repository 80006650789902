import { RestaurantDTO } from './restaurantDTO';

export interface ProductDTO {
  id: string;
  name: string;
  unitPrice: number;
  isAvailable: boolean;
  description: string;
  pictureUrl: string;
  promoDescription: string;
  restaurantId: string;
  restaurant: RestaurantDTO;
  priority: PriorityEnum;
  order: number;
  recommendationPictureURL?: string;
  mobileRecommendationPictureURL?: string;
  miniDescription ?: string;
  isOutOfStock: boolean;
}

export enum PriorityEnum {
  UNKNOWN = -1,
  LOW,
  MEDIUM,
  HIGH,
}
