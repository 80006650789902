/**
 * Generated by orval v6.12.1 🍺
 * Do not edit manually.
 * Protected API
 * OpenAPI spec version: v1
 */

export type ZonePriorityType = typeof ZonePriorityType[keyof typeof ZonePriorityType];


// eslint-disable-next-line @typescript-eslint/no-redeclare
export const ZonePriorityType = {
  LOW: 'LOW',
  STANDARD: 'STANDARD',
  HIGH: 'HIGH',
} as const;
