import React, { useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { ReactComponent as DeleteIcon } from "../../../../../../assets/delete-icon.svg";
import Modal from "react-modal";
import {
  useCreateZone,
  useDeleteZoneById,
  useGetAllZones,
  useGetZoneById,
  useUpdateZoneById,
} from "../../../../../../services/go-api/zone/zone";
import { SaveZoneFormik, ZoneDetailsParams } from "./props";
import { useTranslation } from "../../../../../../components/translation-context/TranslationContext";
import "./styles.css";
import { useFormik } from "formik";
import { createForm } from "../../../../../../utils/formik";
import { saveZoneValidationSchema } from "./validation";
import { EnumPopupIndex } from "../../../../components/popup-context/props";
import { usePopupContext } from "../../../../components/popup-context/context";
import Service from "../../../../../../services/Service";
import SaveButton from "../../../../../../components/header/components/save-button";
import { SAVE_BUTTON_TYPE } from "../../../../../../components/header/components/save-button/props";
import InputText from "../../../../../../components/input-text";
import PageContainer from "../../../../components/page-container";
import Header from "../../../../../../components/header";
import ZoneCompanies from "./components/companies";
import { customStyles } from "../../../../components/popup/customStyle";
import Popup from "../../../../components/popup";
import PopupButton from "../../../../../../components/popup-button";
import BusyIndicator from "../../../../../../components/busy-indicator";
import { ZonePriorityType } from "../../../../../../dtos/go-api";

const ZoneDetails = () => {
  // Attributes
  const history = useHistory();
  const { zoneId } = useParams<ZoneDetailsParams>();
  const [isModalOpen, setIsModalOpen] = useState(false);

  const { data: zone, isLoading } = useGetZoneById(zoneId, {
    query: { enabled: !!zoneId },
  });
  const { translate } = useTranslation();
  const { showPopup } = usePopupContext();
  const saveFormFormik = useFormik<SaveZoneFormik>({
    initialValues: {
      zonePriorityType: zone?.zonePriorityType ?? ZonePriorityType.LOW,
      name: zone?.name ?? "",
      companyIds: zone?.companyZoneDto?.companyIds ?? [],
    },
    onSubmit: handleSave,
    validationSchema: saveZoneValidationSchema({ translate }),
    enableReinitialize: true,
  });
  const saveZoneForm = createForm(saveFormFormik.values, {
    ...saveFormFormik,
  });
  const { mutateAsync: createZone, isLoading: createIsLoading } =
    useCreateZone();
  const { mutateAsync: updateZone, isLoading: updateIsLoading } =
    useUpdateZoneById();
  const { mutateAsync: deleteZone, isLoading: deleteIsLoading } =
    useDeleteZoneById();
  const { refetch: refetchAllZones, isLoading: getIsLoading } =
    useGetAllZones();

  // Handlers
  async function handleSave() {
    // Check mandatory fields
    for (const att in saveFormFormik.values) {
      if (
        (att === "name" && !saveFormFormik.values[att]) ||
        (att === "companyIds" && saveFormFormik.values[att]?.length === 0)
      ) {
        showPopup(EnumPopupIndex.MANDATORY);
        return;
      }
    }
    try {
      if (zoneId) {
        await updateZone({
          zoneId: zoneId,
          data: {
            ...saveFormFormik.values,
          },
        });
      } else {
        await createZone({
          data: {
            ...saveFormFormik.values,
          },
        });
      }
    } catch (e) {
      Service.errors.push(translate("Company already exist in a zone"));
      return;
    }
    await refetchAllZones();
    Service.success.push(
      translate(`Zone ${zoneId ? "updated" : "created"} successfully`)
    );
    history.push("/dashboard/zones");
  }

  async function handleDelete() {
    await deleteZone(
      { zoneId: zoneId },
      {
        onSuccess: async () => {
          await refetchAllZones();
          Service.success.push(translate(`Zone deleted successfully`));
          history.push("/dashboard/zones");
        },
      }
    );
  }

  return (
    <PageContainer>
      <BusyIndicator
        queries={[
          createIsLoading,
          updateIsLoading,
          deleteIsLoading,
          getIsLoading,
        ]}
      >
        <Header
          title={zoneId ? zone?.name : translate("AddNewZone")}
          hasBackButton={true}
          saveButton={
            <SaveButton
              type={
                zoneId ? SAVE_BUTTON_TYPE.SAVE : SAVE_BUTTON_TYPE.SAVE_AND_ADD
              }
              handleClick={handleSave}
              handleDelete={() => setIsModalOpen(true)}
            />
          }
        />
        <div className="zone-details-form-container">
          <div className="zone-details-input-box-container">
            <div className="zone-details-input-title">{translate("Name")}</div>
            <InputText
              value={saveZoneForm.name.value}
              onChange={(event: any) =>
                saveZoneForm.name.onChange(event.target.value)
              }
              placeholder="insert name of the zone"
              required={true}
            />
          </div>
          <ZoneCompanies
            updateCompanyIds={(companyIds: string[]) =>
              saveZoneForm.companyIds.onChange(companyIds)
            }
            companyIds={zone?.companyZoneDto?.companyIds}
          />
        </div>
        <Modal style={customStyles} isOpen={isModalOpen}>
          <Popup
            title="Are you sure you want to delete this zone?"
            icon={<DeleteIcon style={{ width: 66, height: 69 }} />}
            buttons={[
              <PopupButton
                backgroundColor="grey"
                handleClick={() => setIsModalOpen(false)}
                title="Cancel"
              />,
              <PopupButton
                backgroundColor="yellow"
                handleClick={handleDelete}
                title="Yes, delete"
              />,
            ]}
          />
        </Modal>
      </BusyIndicator>
    </PageContainer>
  );
};

export default ZoneDetails;
